@import url(https://fonts.googleapis.com/css?family=Merriweather:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Merriweather:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Merriweather:regular,bold,italic&subset=latin,latin-ext);
html {
  min-height: 100%;
  height: 100%;

}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open_Sans', sans-serif;
  background-size: 100%;
  background-position: bottom;
  height: 100%;
  width: 100%;
  min-height: 100%;
  font-weight: 100;


}

.major-heading h1 {

  font-size: 3.2rem;
  font-weight: bolder;
  font-family: Helvetica, sans-serif;

}


.image-background {
  position: relative;
  padding: 0;
  bottom: 0 0 80px;
  font-family: 'Open_Sans', sans-serif;
  background-size: 100%;
  background-position: bottom;
  background-color: #daf4f1;
  width: 100vw;
  min-height: 100vh;
  /*background: white url(../../files/MainPageBackground.svg) no-repeat;*/

}




Form {
  padding: 20px;
  margin-top: 60px;

}

Button {
  background-color: #0099CC;

}

Button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-brown {
  background-color: brown;
  border-color: brown;
  color: white;
}

.btn-orange {
  background-color: orange !important;
  border-color: orange !important;
}

.btn-grey {
  background-color: grey !important;
  border-color: grey !important;
}

.btn-blue {
  background-color: #0099CC !important;
  border-color: #0099CC !important;
  padding: 5px 32px !important;
  display: inline-block;
}

.btn-blue2 {
  background-color: #0099CC !important;
  border-color: #0099CC !important;
  padding: 5px 20px !important;
  display: inline-block;
}

.btn-complementary2 {
  background-color: #F58437 !important;
  border-color: #F58437 !important;
  padding: 5px 20px !important;
  display: inline-block;
}



.bg-whiteBlue {
  background-color: white !important;
  border-color: #0099CC !important;
  color: #0099CC !important;
  padding: 1rem 1.5rem !important;
  display: inline-block;

}

.txt-pink {
  color: #ff7000
}

.btn-rounded {

  border-radius: 36px !important;

}

.btn-large {
  padding: .6rem 1.1rem !important;
  font-size: 1.3rem !important;
  border-radius: 36px !important;
  min-width: 10rem !important;
}

.btn-medium {
  padding: .6rem .9rem !important;
  font-size: 1.15rem !important;
  border-radius: 36px !important;
}

/*need to add important to the below if I want this to work. Though it's still not working right now even with important--I don't know why */




.link {
  a:link {
    color: #0099CC
  }

  ;

  a:hover {
    color: #00394d
  }

  ;


}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {

  line-height: 140% !important;
}
.button {
    background-color: #ff7000; 
    border: none;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 4px 2px;
    cursor: pointer;
  }



  .text {
    border: none;
    color: #0099CC;

  }
  .button5 {
    border-radius: 50% !important;
    width: 40px;
    height:40px;
  }


  .color-nav{
    background-color:#d9edf2
  }

  .linkstyle {
    font-family:  'Merriweather', serif;
    text-transform: initial;
    color: black;
  }

  .navbar-links {

    padding-top:6px;
  }
 
  .navbar-nav .nav-item .nav-link .nav-dropdown {

    color: #d9edf2;
    font-size: 18px;
  }

  .navbar.active{
  background-color: white;
      box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);

  }


#nav-dropdown{
    background-color:transparent;
}

  .linkstyle:hover {
  -webkit-columns: #0099CC;
          columns: #0099CC;
}

.navbar-toggler-icon:active {

    border: none;
    outline: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  border: none;
    outline: none;
  
}
.popup_popup__TsT62 {  
    position: fixed;  
    width: 25%;  
    height: 25%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color:rgba(0,0,0, 0.5);  
    z-index:10;
  }  
  .popup_popup_inner__1GZzt {  
    position: absolute;  
    left: 15%;  
    right: 15%;  
    top: 15%;  
    bottom: 15%;  
    margin: auto;  
    border-radius: 20px;  
    background: white;  
    z-index:10;
  }
  

.tooltip  .tooltip-inner {
  background-color: "white" !important;
  border: 1px solid "white" !important;
  color: "black" !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  box-shadow: 5px 5px 10px #888888;
}
.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-shadow {

    box-shadow: 5px 5px 10px #888888;
margin-left: auto;
margin-right: auto;
}

.grey-background {

        position: relative;
        background-color:#f0f0f5;
        width: 100%;
        height: 100%;
}

.publicfooter {
position:absolute;
left:0;
bottom:0;
right:0;
height: 80px;
font-size: 18px;
line-height: 140% !important;
font-family: 'Merriweather', serif;

}





.testimonial blockquote {
  display: block;
  border-width: 2px 0;
  border-style: solid;
  border-color: #eee;
  padding: 1.5em 0 0.5em;
  margin: 1.5em 0;
  position: relative;
}

.testimonial blockquote:before {
  content: '\201C';
  position: absolute;
  top: 0em;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background:transparent;
  width: 3rem;
  height: 2rem;
  font: 6em/1.08em 'PT Sans', sans-serif;
  color: #666;
  text-align: center;
}

.testimonial blockquote:after{
  content: "\2013 \2003" attr(cite);
  display: block;
  text-align: right;
  font-size: 1.2em;
  font-style: italic;
 
}


  .btn-carousel-progress {
    border-radius: 50% !important;
    background-color:#666  !important;
    border: none !important;
    padding: .1rem .1rem .4rem .1rem !important;
    margin: .1rem .1rem .1rem .1rem !important;
    outline: 0 !important;
    height: 1.8rem !important;
    width: 1.8rem !important;
    
  }

  .btn-carousel-progress:focus {
  outline: none !important;
  box-shadow: none !important;
}


  .btn-carousel-indicators {
    border-radius: 50% !important;
    background-color:white  !important;
    border-color: #666 !important;
        width: 1rem !important;
    height: 1rem !important;
        padding: .1rem .1rem .4rem .1rem !important;
    margin: .1rem .1rem .1rem .1rem !important;
    
  }

  .btn-carousel-indicators:focus {
  outline: none !important;
  box-shadow: none !important;
}

  .btn-carousel-indicators.selected {
    background-color:#666  !important;

    
  }

.shadow-textarea textarea.form-control::-webkit-input-placeholder {

}
.shadow-textarea textarea.form-control::placeholder {

}
.shadow-textarea textarea.form-control {
   padding-left: 0.8rem;
}
.text-align-center{
   text-align: center;
}
body {
    margin: 0;
    font-family: Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
    
  }
  

header {
    padding: 156px 0 100px;
}

section {
    padding: 150px 0;
}
  
.App .IFrame {
    text-align: center;
  }
  
  
  .App-logo {
    height: 40vmin;
  }
  
  header {
    padding: 156px 0 100px;
  }
  
  section {
    padding: 150px 0;
  }
  
  .App-link {
    color: #0099CC;
  }
  
.form-check.traditional  {


}
.light-highlight-text-for-feedback{
   -webkit-text-decoration-color: grey;
           text-decoration-color: grey;
   font: italic;
}
.hidden{
   display: none;
}
.FaThumbsDown{
   -webkit-transform: rotateY(180deg);
           transform: rotateY(180deg);
 }
 * {
   box-sizing: border-box;
 }
 .zoom {
    
   padding: 50px;
   background-color: green;
   transition: -webkit-transform .2s;
   transition: transform .2s;
   transition: transform .2s, -webkit-transform .2s;
   width: 200px;
   height: 200px;
   margin: 0 auto;
 }
 
 .alpha {
   background-color: yellow;
   font-weight: bold !important
 }

.form-check {
    margin:4px;
    background-color:#EFEFEF;
    border-radius:20px;
    border:5px solid #D0D0D0;
    overflow:auto;
    float:left;
    padding:3px 20px 0px 3px; /*need to account for covering the actual check box */

}

.form-check label {
    float:center;
    width:8.8em;
}

.form-check label span {
    text-align:left;
    
    display:block;
}

.form-check label input {
    position:absolute;
    top:-20px;
}

.form-check input:checked + span {
    /*background-color:#911;*/
    color:#fff;

}

  
 .checkbox-form {
    -webkit-column-count: 6;
            column-count: 6;
    -webkit-column-width: 200px;
            column-width: 200px;
  }

  Form{
    padding: 20px !important;
    margin-top: 0px !important;
   
  }



.header{
cursor: pointer;
border: solid 1px #f2f2f2;
padding: 15px;
background-color: #0099CC;
color: #FFF;
font-family: 'Open Sans', sans-serif;
overflow-wrap: break-word;
border-radius:20px;
}

.link{
cursor: pointer;
border: solid 1px #f2f2f2;
padding: 15px;
color: #0099CC;
font-family: 'Open Sans', sans-serif;
overflow-wrap: break-word;
border-radius:20px;
}

.h-blue{
background-color: #0099CC;
}

.h-grey{
background-color: grey;
}

.h-green{
    color: #FFF;
background-color: green;
}

.h-purple{
    color: #FFF;
background-color: purple;
}
.invisibleItem { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 5px solid #0099CC;
}

.radioCustomLabel {
  margin: 0;
  font-size: 24px;

}

input[type=radio] {
    border: 0px;
    width: 100%;
    height: 1em;
}
/*need to add important to the below if I want this to work. Though it's still not working right now even with important--I don't know why */

Button{
    border-radius: 12px !important;
}

.lavendar-txt{
  color: #6666ff !important; 
}

.red-txt{
  color: #cc0000 !important; 
}

.red-tooltip + .tooltip > .tooltip-inner {background-color: #cc0000;}

.lavendar-btn{
  background-color: #6666ff !important; 
  border: 10px !important;
  color: white !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}


.whiteLavendar-btn{
  background-color: white !important; 
  border-width: 50;
  border-color: #6666ff !important;
  color:#6666ff !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}
.whiteRed-btn{
  background-color: white !important; 
  border-width: 50;
  border-color: #cc0000 !important;
  color:#cc0000 !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}

.red-btn{
  background-color: #cc0000 !important; 
  border: 10px !important;
  color: white !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}

.choice-btn{
  background-color: white !important; 
  border-width: 50;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;
  width: 11rem !important;
}

.choice-btn.dark{
  border-color: #213378 !important;
  color:#213378!important;

}


.choice-btn.dark.selected{
  background-color: #213378 !important; 
  color: white !important; 
}

.choice-btn.light{
  border-color: #f58437 !important;
  color:#f58437 !important;

}


.choice-btn.light.selected{
  background-color: #f58437 !important; 
  color:white !important; 
}




.choice-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}



  .termsAndConditions {

    margin: 0px 0 ;
    padding: 0px !important;
    margin-bottom: 3px;
  }




 .left_navlinks a:hover,
 .left_navlinks .active a:active {
  
  background-color: #0099CC;
  color: white;

}
.shadow-textarea textarea.form-control::-webkit-input-placeholder {

}
.shadow-textarea textarea.form-control::placeholder {

}
.shadow-textarea textarea.form-control {
   padding-left: 0.8rem;
}
.text-align-center{
   text-align: center;
}
.light-highlight-text-for-feedback{
   -webkit-text-decoration-color: grey;
           text-decoration-color: grey;
   font: italic;
}
.hidden{
   display: none;
}
.FaThumbsDown{
   -webkit-transform: rotateY(180deg);
           transform: rotateY(180deg);
 }
 * {
   box-sizing: border-box;
 }
 .zoom {
    
   padding: 50px;
   background-color: green;
   transition: -webkit-transform .2s;
   transition: transform .2s;
   transition: transform .2s, -webkit-transform .2s;
   width: 200px;
   height: 200px;
   margin: 0 auto;
 }
 
 .alpha {
   background-color: yellow;
   font-weight: bold !important
 }

