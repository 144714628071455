
  
 .checkbox-form {
    -webkit-column-count: 6;
       -moz-column-count: 6;
            column-count: 6;
    -webkit-column-width: 200px;
       -moz-column-width: 200px;
            column-width: 200px;
  }

  Form{
    padding: 20px !important;
    margin-top: 0px !important;
   
  }


