@import url('https://fonts.googleapis.com/css?family=Merriweather:regular,bold,italic&subset=latin,latin-ext');


.button {
    background-color: #ff7000; 
    border: none;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 4px 2px;
    cursor: pointer;
  }



  .text {
    border: none;
    color: #0099CC;

  }
  .button5 {
    border-radius: 50% !important;
    width: 40px;
    height:40px;
  }


  .color-nav{
    background-color:#d9edf2
  }

  .linkstyle {
    font-family:  'Merriweather', serif;
    text-transform: initial;
    color: black;
  }

  .navbar-links {

    padding-top:6px;
  }
 
  .navbar-nav .nav-item .nav-link .nav-dropdown {

    color: #d9edf2;
    font-size: 18px;
  }

  .navbar.active{
  background-color: white;
      box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);

  }


#nav-dropdown{
    background-color:transparent;
}

  .linkstyle:hover {
  columns: #0099CC;
}

.navbar-toggler-icon:active {

    border: none;
    outline: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  border: none;
    outline: none;
  
}