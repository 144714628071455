body {
    margin: 0;
    font-family: Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
    
  }
  

header {
    padding: 156px 0 100px;
}

section {
    padding: 150px 0;
}
  
.App .IFrame {
    text-align: center;
  }
  
  
  .App-logo {
    height: 40vmin;
  }
  
  header {
    padding: 156px 0 100px;
  }
  
  section {
    padding: 150px 0;
  }
  
  .App-link {
    color: #0099CC;
  }
  
.form-check.traditional  {


}