.invisibleItem { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 5px solid #0099CC;
}

.radioCustomLabel {
  margin: 0;
  font-size: 24px;

}

input[type=radio] {
    border: 0px;
    width: 100%;
    height: 1em;
}