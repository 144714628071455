.form-check {
    margin:4px;
    background-color:#EFEFEF;
    border-radius:20px;
    border:5px solid #D0D0D0;
    overflow:auto;
    float:left;
    padding:3px 20px 0px 3px; /*need to account for covering the actual check box */

}

.form-check label {
    float:center;
    width:8.8em;
}

.form-check label span {
    text-align:left;
    
    display:block;
}

.form-check label input {
    position:absolute;
    top:-20px;
}

.form-check input:checked + span {
    /*background-color:#911;*/
    color:#fff;

}