.popup {  
    position: fixed;  
    width: 25%;  
    height: 25%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color:rgba(0,0,0, 0.5);  
    z-index:10;
  }  
  .popup_inner {  
    position: absolute;  
    left: 15%;  
    right: 15%;  
    top: 15%;  
    bottom: 15%;  
    margin: auto;  
    border-radius: 20px;  
    background: white;  
    z-index:10;
  }
  