/*need to add important to the below if I want this to work. Though it's still not working right now even with important--I don't know why */

Button{
    border-radius: 12px !important;
}

.lavendar-txt{
  color: #6666ff !important; 
}

.red-txt{
  color: #cc0000 !important; 
}

.red-tooltip + .tooltip > .tooltip-inner {background-color: #cc0000;}

.lavendar-btn{
  background-color: #6666ff !important; 
  border: 10px !important;
  color: white !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}


.whiteLavendar-btn{
  background-color: white !important; 
  border-width: 50;
  border-color: #6666ff !important;
  color:#6666ff !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}
.whiteRed-btn{
  background-color: white !important; 
  border-width: 50;
  border-color: #cc0000 !important;
  color:#cc0000 !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}

.red-btn{
  background-color: #cc0000 !important; 
  border: 10px !important;
  color: white !important;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;

}

.choice-btn{
  background-color: white !important; 
  border-width: 50;
  padding: 8px !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 18px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;
  width: 11rem !important;
}

.choice-btn.dark{
  border-color: #213378 !important;
  color:#213378!important;

}


.choice-btn.dark.selected{
  background-color: #213378 !important; 
  color: white !important; 
}

.choice-btn.light{
  border-color: #f58437 !important;
  color:#f58437 !important;

}


.choice-btn.light.selected{
  background-color: #f58437 !important; 
  color:white !important; 
}




.choice-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

