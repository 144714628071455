.header{
cursor: pointer;
border: solid 1px #f2f2f2;
padding: 15px;
background-color: #0099CC;
color: #FFF;
font-family: 'Open Sans', sans-serif;
overflow-wrap: break-word;
border-radius:20px;
}

.link{
cursor: pointer;
border: solid 1px #f2f2f2;
padding: 15px;
color: #0099CC;
font-family: 'Open Sans', sans-serif;
overflow-wrap: break-word;
border-radius:20px;
}

.h-blue{
background-color: #0099CC;
}

.h-grey{
background-color: grey;
}

.h-green{
    color: #FFF;
background-color: green;
}

.h-purple{
    color: #FFF;
background-color: purple;
}