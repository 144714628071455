@import url('https://fonts.googleapis.com/css?family=Merriweather:regular,bold,italic&subset=latin,latin-ext');


.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  box-shadow: 5px 5px 10px #888888;
}
.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-shadow {

    box-shadow: 5px 5px 10px #888888;
margin-left: auto;
margin-right: auto;
}

.grey-background {

        position: relative;
        background-color:#f0f0f5;
        width: 100%;
        height: 100%;
}

.publicfooter {
position:absolute;
left:0;
bottom:0;
right:0;
height: 80px;
font-size: 18px;
line-height: 140% !important;
font-family: 'Merriweather', serif;

}





.testimonial blockquote {
  display: block;
  border-width: 2px 0;
  border-style: solid;
  border-color: #eee;
  padding: 1.5em 0 0.5em;
  margin: 1.5em 0;
  position: relative;
}

.testimonial blockquote:before {
  content: '\201C';
  position: absolute;
  top: 0em;
  left: 50%;
  transform: translate(-50%, -50%);
  background:transparent;
  width: 3rem;
  height: 2rem;
  font: 6em/1.08em 'PT Sans', sans-serif;
  color: #666;
  text-align: center;
}

.testimonial blockquote:after{
  content: "\2013 \2003" attr(cite);
  display: block;
  text-align: right;
  font-size: 1.2em;
  font-style: italic;
 
}


  .btn-carousel-progress {
    border-radius: 50% !important;
    background-color:#666  !important;
    border: none !important;
    padding: .1rem .1rem .4rem .1rem !important;
    margin: .1rem .1rem .1rem .1rem !important;
    outline: 0 !important;
    height: 1.8rem !important;
    width: 1.8rem !important;
    
  }

  .btn-carousel-progress:focus {
  outline: none !important;
  box-shadow: none !important;
}


  .btn-carousel-indicators {
    border-radius: 50% !important;
    background-color:white  !important;
    border-color: #666 !important;
        width: 1rem !important;
    height: 1rem !important;
        padding: .1rem .1rem .4rem .1rem !important;
    margin: .1rem .1rem .1rem .1rem !important;
    
  }

  .btn-carousel-indicators:focus {
  outline: none !important;
  box-shadow: none !important;
}

  .btn-carousel-indicators.selected {
    background-color:#666  !important;

    
  }
