.light-highlight-text-for-feedback{
   text-decoration-color: grey;
   font: italic;
}
.hidden{
   display: none;
}
.FaThumbsDown{
   transform: rotateY(180deg);
 }
 * {
   box-sizing: border-box;
 }
 .zoom {
    
   padding: 50px;
   background-color: green;
   transition: transform .2s;
   width: 200px;
   height: 200px;
   margin: 0 auto;
 }
 
 .alpha {
   background-color: yellow;
   font-weight: bold !important
 }
